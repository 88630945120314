import { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";

import Toggle from "../../../../components/toggle/Toggle";
import { RoundedLoader } from "../../../../components/loader/RoundedLoader";
import Modal, { IModal } from "../../../../components/modal/Modal";
import { licensesContext } from "../../../../context/licensesContext";
import { License, PaymentRecurrence } from "../../../../types/license";
import CustomButton from "../../../../components/button/button";

export const VideoPlugins: React.FC = () => {
    const { t } = useTranslation();
    const { data, updateLicenseAutomaticRenewal, loading, error } = useContext(licensesContext);
    const [modalState, setModalState] = useState({} as IModal);
    const license = data?.[0];

    const openModalPluginAutomaticRenewal = useCallback(
        (license: License) => {
            const automaticRenewalUpdated = !license.automaticRenewal;

            // Default date end for monthly subscriptions
            let dateEnd = moment.utc().endOf("month").endOf("day").toDate();

            if (license.paymentRecurrence === PaymentRecurrence.YEARLY) {
                // For yearly subscriptions, find the next occurrence from the start date
                const startDate = moment(license.dateStart);
                const today = moment();
                const yearsDiff = today.diff(startDate, "years");
                const nextOccurrence = startDate.clone().add(yearsDiff + 1, "year");
                dateEnd = nextOccurrence.toDate();
            }

            if (!automaticRenewalUpdated) {
                setModalState({
                    show: true,
                    showCancel: true,
                    title: t("services-management.plugin.modal.automatic-renewal.title.deactivate"),
                    message: t(
                        "services-management.plugin.modal.automatic-renewal.message.deactivate",
                        {
                            dateEnd: moment.utc(dateEnd).format("LL")
                        }
                    ),
                    onConfirm: () => {
                        setModalState({ ...modalState, show: false });
                        updateLicenseAutomaticRenewal(license.uuid, {
                            automaticRenewal: automaticRenewalUpdated
                        });
                    },
                    onCancel: () => setModalState({ ...modalState, show: false }),
                    confirmBtnText: t("services-management.plugin.modal.confirm"),
                    cancelBtnText: t("services-management.plugin.modal.cancel")
                });
            } else {
                updateLicenseAutomaticRenewal(license.uuid, {
                    automaticRenewal: automaticRenewalUpdated
                });
            }
        },
        [license, t, updateLicenseAutomaticRenewal, modalState]
    );

    const openModalPluginTrialCancellation = useCallback((license: License) => {
        setModalState({
            show: true,
            showCancel: true,
            title: t("services-management.plugin.modal.trial.title.deactivate"),
            message: t("services-management.plugin.modal.trial.message.deactivate"),
            onConfirm: () => {
                setModalState({ ...modalState, show: false });
                updateLicenseAutomaticRenewal(license.uuid, {
                    automaticRenewal: false
                });
            },
            onCancel: () => setModalState({ ...modalState, show: false }),
            confirmBtnText: t("services-management.plugin.modal.confirm"),
            cancelBtnText: t("services-management.plugin.modal.cancel")
        });
    }, []);

    const getIsLicenseExpired = useCallback(
        (license: License) => moment().endOf("day").isAfter(license.dateEnd),
        []
    );

    const getIsLicenseTrial = useCallback(
        (license: License) => moment.utc(license.dateStart).add(7, "days").isAfter(moment()),
        []
    );

    useEffect(() => {
        if (error) {
            setModalState({
                show: true,
                showCancel: false,
                title: t("error.error-title"),
                message: t("error.internalError"),
                onConfirm: () => {
                    setModalState({ ...modalState, show: false });
                },
                confirmBtnText: t("services-management.plugin.modal.confirm"),
                cancelBtnText: t("services-management.plugin.modal.cancel")
            });
        }
    }, [error, modalState]);

    const renderRenewalToggle = (license: License) => {
        if (license.blocked || getIsLicenseTrial(license) || getIsLicenseExpired(license))
            return null;

        return (
            <div className="d-flex align-items-center gap-2">
                <span
                    className={`ms-2 ${license.automaticRenewal ? "color-primary" : "color-clear-blue-grey"}`}
                >
                    {t("services-management.plugin.renewal")}
                </span>
                <Toggle
                    isChecked={license.automaticRenewal}
                    onClick={() => openModalPluginAutomaticRenewal(license)}
                    disabled={loading}
                />
            </div>
        );
    };

    const renderCancelTrialButton = (license: License) => {
        if (!getIsLicenseTrial(license) || getIsLicenseExpired(license)) return null;

        return (
            <div className="d-flex align-items-center gap-2">
                <CustomButton
                    buttonText={t("services-management.plugin.cancel-trial", {
                        days: moment.utc(license.dateStart).add(7, "days").diff(moment(), "days")
                    })}
                    onClick={() => openModalPluginTrialCancellation(license)}
                    classNameType="mainWhite"
                />
            </div>
        );
    };

    const renderReactivateButton = (license: License) => {
        if (!getIsLicenseExpired(license) || license.blocked) return null;

        return (
            <div className="d-flex align-items-center gap-2">
                <CustomButton
                    buttonText={t("services-management.plugin.reactivate")}
                    onClick={() => openModalPluginReactivation(license)}
                />
            </div>
        );
    };

    const renderLicenseStatus = (license: License) => {
        if (license.blocked || getIsLicenseExpired(license))
            return t("services-management.plugin.deactivated").toUpperCase();
        return t("services-management.plugin.activated").toUpperCase();
    };

    const openModalPluginReactivation = useCallback(
        (license: License) => {
            setModalState({
                show: true,
                showCancel: true,
                title: t("services-management.plugin.modal.trial.title.activate"),
                message: t("services-management.plugin.modal.trial.message.activate"),
                onConfirm: () => {
                    setModalState({ ...modalState, show: false });
                    updateLicenseAutomaticRenewal(license.uuid, {
                        automaticRenewal: true
                    });
                },
                onCancel: () => setModalState({ ...modalState, show: false }),
                confirmBtnText: t("services-management.plugin.modal.confirm"),
                cancelBtnText: t("services-management.plugin.modal.cancel")
            });
        },
        [modalState, t]
    );

    const renderDateInformation = (license: License) => {
        if (license.blocked || getIsLicenseExpired(license)) return null;

        if (getIsLicenseTrial(license)) {
            return (
                <>
                    <div className="col-5 col-lg-2">
                        <span className="mr-1 text-bold">
                            {t("services-management.plugin.trial-ending-date")}
                        </span>
                    </div>
                    <div className="col-7 col-lg-3">
                        <span className="account-value">
                            {moment.utc(license.dateStart).add(7, "days").format("DD/MM/YYYY")}
                        </span>
                    </div>
                    <div className="account-more-info">
                        <p className="mt-3">{t("services-management.plugin.more-info.trial")}</p>
                    </div>
                </>
            );
        }

        return (
            <>
                {license.dateEnd && (
                    <>
                        <div className="col-3 col-lg-2">
                            <span className="mr-1 text-bold">
                                {t("services-management.plugin.commitment-end-date")}
                            </span>
                        </div>
                        <div className="col-7 col-lg-3">
                            <span className="account-value">
                                <div className="plan-container">
                                    {moment.utc(license.dateEnd).format("DD/MM/YYYY")}
                                </div>
                            </span>
                        </div>
                    </>
                )}
                {!license.automaticRenewal && (
                    <div className="account-more-info">
                        <p className="mt-3">
                            {t("services-management.plugin.more-info.automatic-renewal")}
                        </p>
                    </div>
                )}
            </>
        );
    };

    if (!license) return null;

    return (
        <div className="middle-container my-account-view">
            {/* Header section */}
            <div className="row flex-row">
                <div className="w-100 d-flex align-items-center justify-content-between mb-3 mb-lg-5">
                    <div className="d-flex align-items-center account-title-container">
                        <h2 className="account-title mb-0 me-lg-4">
                            Blur<strong className="color-primary">It</strong> Plugin
                        </h2>
                        <RoundedLoader isLoading={loading} />
                    </div>
                    {renderCancelTrialButton(license)}
                    {renderReactivateButton(license)}
                    {renderRenewalToggle(license)}
                </div>
            </div>

            {/* License information section */}
            <div className="d-flex flex-column gap-3">
                {/* Status row */}
                <div className="row align-items-center">
                    <div className="col-5 col-lg-2">
                        <span className="mr-1 text-bold">
                            {t("services-management.plugin.status")}
                        </span>
                    </div>
                    <div className="col-7 col-lg-10">
                        <span className="account-value">
                            <div className="plan-container">{renderLicenseStatus(license)}</div>
                        </span>
                    </div>
                </div>

                {/* Billing period - only shown for active licenses */}
                {!license.blocked && !getIsLicenseExpired(license) && (
                    <div className="row align-items-center">
                        <div className="col-5 col-lg-2">
                            <span className="mr-1 text-bold">
                                {t("services-management.plugin.billing-period")}
                            </span>
                        </div>
                        <div className="col-7 col-lg-10">
                            <span className="account-value">
                                <div className="plan-container">{license.paymentRecurrence}</div>
                            </span>
                        </div>
                    </div>
                )}

                {/* Date information row */}
                <div className="row align-items-center">{renderDateInformation(license)}</div>

                {/* Add expired license information */}
                {getIsLicenseExpired(license) && !license.blocked && (
                    <div className="account-more-info row align-items-center mt-3">
                        <h3 className="mb-2 me-lg-4">
                            {t("services-management.plugin.more-info.not-subscribed.title")}
                        </h3>
                        <p>
                            <Trans
                                i18nKey="services-management.plugin.more-info.not-subscribed.text"
                                components={{
                                    a: (
                                        <a
                                            href="/documentation"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        />
                                    ),
                                    strong: <strong />
                                }}
                            />
                        </p>
                    </div>
                )}
            </div>

            {/* Blocked license information */}
            {license.blocked && (
                <div className="account-more-info row align-items-center mt-3">
                    <h3 className="mb-2 me-lg-4">
                        {t("services-management.plugin.more-info.blocked.title")}
                    </h3>
                    <p>
                        <Trans
                            i18nKey="services-management.plugin.more-info.blocked.text"
                            components={{
                                a: <a href="mailto:support@blurit.io" />
                            }}
                        />
                    </p>
                </div>
            )}

            <Modal {...modalState} />
        </div>
    );
};
